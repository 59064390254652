import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  Banner,
} from 'components';
import { BASE_URL } from 'config';

const SchlumbergerPage = () => (
  <Layout page="about/schlumberger">

    <Helmet
      title="Schlumberger Reunion Tour – Asia Alliance"
      meta={[
        {
          name: 'description', content: 'This incredible tour will take you to some of the most popular locations in New Zealand’s South Island.',
        },
        { name: 'og:title', content: 'Schlumberger Reunion Tour – Asia Alliance' },
        {
          name: 'og:description', content: 'This incredible tour will take you to some of the most popular locations in New Zealand’s South Island.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/schlumberger/` }]}
    />

    <Banner backgroundImage="http://static-hakatours.imgix.net/register_my_interest_hero_image.jpg?h=490&bm=screen&fit=crop&auto=format">
      <h1 className="c-heading c-heading--h0">Schlumberger Reunion Tour – Asia Alliance</h1>
    </Banner>
    <section className="l-section">
      <div className="l-container l-container--small">
        <div>
          <h2 className="l-contact-us__title c-heading c-heading--h2 u-text--center">Register Your Interest</h2>
          <p>
            This incredible tour will take you to some of the most popular locations in New Zealand’s South Island. Experience rugged beaches, breathtaking Fjords,
            jaw-dropping mountain ranges, glacier valleys and bright blue lakes on this 9 or 5 day adventure.
          </p>
          <p>
            Immerse yourself in a wide range of cultural, adventure and eco-activities whilst you learn about New Zealand’s rich culture and history.
            Your local Kiwi guide knows NZ like the back of their hand and will be able to show you many of our little treasures.
          </p>
          <p>
            It will be a reunion to remember!
          </p>
          <p>
            <b>Dates:</b> 24th March – 1st April 2020<br />
            <b>9 day option:</b> $4100 NZD per person<br />
            <b>5 day option:</b> $2700 NZD per person<br />
          </p>
          <br />
          <div>
            <form
              acceptCharset="UTF-8"
              action="https://qj243.infusionsoft.com/app/form/process/785ad0c3103e0d385cff805597db3e8d"
              className="infusion-form"
              method="POST"
            >
              <input name="inf_form_xid" type="hidden" value="785ad0c3103e0d385cff805597db3e8d" />
              <input name="inf_form_name" type="hidden" value="Register Interest Form" />
              <input name="infusionsoft_version" type="hidden" value="1.70.0.101490" />
              <div className="infusion-field">
                <input required className="infusion-field-input-container" id="inf_field_FirstName" name="inf_field_FirstName" type="text" placeholder="First Name *" />
              </div>
              <div className="infusion-field">
                <input required className="infusion-field-input-container" id="inf_field_LastName" name="inf_field_LastName" type="text" placeholder="Last Name *" />
              </div>
              <div className="infusion-field">
                <input required className="infusion-field-input-container" id="inf_field_Email" name="inf_field_Email" type="text" placeholder="Email *" />
              </div>
              <div className="infusion-field">
                <label>Number of travellers *</label>
                <div className="infusion-radio">
                  <div className="option-traveller-container">
                    <span className="infusion-option">
                      <input id="inf_option_Numberoftravellers_2070" name="inf_option_Numberoftravellers" type="radio" value="2070" />
                      <label>1</label>
                    </span>
                    <span className="infusion-option">
                      <input id="inf_option_Numberoftravellers_2072" name="inf_option_Numberoftravellers" type="radio" value="2072" />
                      <label>2</label>
                    </span>
                    <span className="infusion-option">
                      <input id="inf_option_Numberoftravellers_2074" name="inf_option_Numberoftravellers" type="radio" value="2074" />
                      <label>3</label>
                    </span>
                    <span className="infusion-option">
                      <input id="inf_option_Numberoftravellers_2076" name="inf_option_Numberoftravellers" type="radio" value="2076" />
                      <label>4</label>
                    </span>
                  </div>
                </div>
              </div>
              <div className="infusion-field">
                <label>Length of tour *</label>
                <div className="infusion-radio">
                  <div className="option-traveller-container">
                    <span className="infusion-option">
                      <input id="inf_option_Lengthoftour_2078" name="inf_option_Lengthoftour" type="radio" value="2078" />
                      <label>5 day tour</label>
                    </span>
                    <span className="infusion-option">
                      <input id="inf_option_Lengthoftour_2080" name="inf_option_Lengthoftour" type="radio" value="2080" />
                      <label>9 day tour</label>
                    </span>
                  </div>
                </div>
              </div>
              <div className="l-container condition-text">
                *costs are based on a minimum of 50 guests booking the 9 day trip and may be subject to change.
              </div>
              <div className="infusion-submit">
                <button className="infusion-recaptcha" id="recaptcha_785ad0c3103e0d385cff805597db3e8d" type="submit">Register Interest</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="l-container l-container--small">
        <h2>Day 1: 24th March</h2>
        <h2>Arrive Christchurch</h2>
        <p>
          Kia ora, welcome to New Zealand and to Christchurch, the gateway to the South Island. Your guide will greet you at your hotel around midday for registrations and a group lunch.
          If you are feeling up for it after having a freshen up, your guide can show you around the city including visiting some of the highlights.
          Witness the regeneration that has taken place since the 2011 earthquake as this beautiful town gets back on its feet. The Christchurch Gardens are a spectacular place to visit.
          Indulge in an included welcome dinner this evening.
        </p>
        <p><b><i>Included Meals:</i></b> Welcome Lunch, Dinner</p>
        <p><b><i>Included Accommodation:</i></b> Crowne Plaza Christchurch or similar</p>
        <img src="https://static-hakatours.imgix.net/christchurch_tram.jpg?h=418&w=652&fit=crop&auto=format" alt="Christchurch Tram" />
      </div>
      <br />
      <div className="l-container l-container--small">
        <h2>Day 2: 25th March</h2>
        <h2>Christchurch to Glacier Country</h2>
        <p><b>Travel time:</b> Approximately 5 hour train journey.</p>
        <p>177 KM, approx 3 hours driving time from Greymouth to Glacier Country.</p>
        <p>Start time: Train departs Christchurch 8:15am</p>
        <p>
          This morning you will board the TranzAlpine Scenic Rail Journey which will take you across to Greymouth and through the Arthurs Pass.
          You will get to relax on this modern train and see views that you wouldn’t get to experience if you were in a vehicle.
          Along this journey you’ll see epic vistas, travel the edges of the ice-fed Waimakariri River, traverse the Southern Alps, and see miles of native beech forest.
          Lunch is not included today, however there is an on-board cafe on the train where you can purchase your lunch from.
        </p>
        <p>
          Upon arriving in Greymouth your guide will then transfer you to Glacier ’Country’ where you will be staying in Franz Josef or Fox Glacier.
          On the way you may take a rest break in Hokitika and stretch your legs and check out the beach if weather permits.
        </p>
        <p>
          Glacier Country has so much to offer! You can choose to take the hour-return hike to the face of the Glacier which is surrounded by stunning views of the Valley or relax in the Franz Josef glacier hot pools.
          To wind down in the evening there will be a restaurant group dinner included.
        </p>
        <p><b><i>Included Meals:</i></b> Breakfast, Dinner</p>
        <p><b><i>Included Activities:</i></b> TranzAlpine Scenic Rail Journey</p>
        <p><b><i>Suggested Activities:</i></b> Glacier Hot Pools</p>
        <p><b><i>Included Accommodation:</i></b> Scenic Hotel or similar</p>
        <img src="https://static-hakatours.imgix.net/Franz_Josef_Glacier_2.jpg?h=418&w=652&fit=crop&auto=format" alt="Franz Josef Glacier" />
      </div>
      <br />
      <div className="l-container l-container--small">
        <h2>Day 3: 26th March</h2>
        <h2>Glacier Country to Wanaka</h2>
        <p><b>Travel time:</b> 286 KM, approx 4 hours driving time. This morning we will aim to leave at approximately 10-11am (this will be dependent on heli-hike times).</p>
        <p>
          Today it’s onto Wanaka, a year-round destination which is surrounded by towering mountains and a glacial blue lake with plenty of things to do and with a thriving young vibe in town.
          A group picnic lunch will be included on the way to Wanaka today. There are plenty of secret spots in store today as you make your way to Wanaka.
          You can stop off at Lake Matheson which is near Fox Glacier. You can take a short walk that leads you to the lake where you will be greeted with reflections of Mt Cook, New Zealand’s tallest mountain.
          You might like to visit the Blue Pools, there is a 1.5km return track that leads you to the pools themselves. These are set amongst mature beech and podocarp forest.
          The water in the pools is so clear with water flowing into the Makarora River.
        </p>
        <p><b><i>Included Meals:</i></b> Breakfast, Picnic Lunch, Dinner</p>
        <p><b><i>Included Accommodation:</i></b> Edgewater Wanaka or similar</p>
        <img src="https://static-hakatours.imgix.net/Wanaka_Tree_2.jpg?h=418&w=652&fit=crop&auto=format" alt="Tekapo" />
      </div>
      <br />
      <div className="l-container l-container--small">
        <h2>Day 4: 27th March</h2>
        <h2>Wanaka to Queenstown</h2>
        <p>
          Sit back and relax, this morning you will be leaving at approximately 11:00am and taking a scenic drive from Wanaka to Queenstown.
        </p>
        <p><b>Travel time:</b> 74 KM, approx 1.5 hours driving time</p>
        <p>
          The Winery Restaurant at Carrick Winery provides a relaxed atmosphere in which to enjoy a delicious lunch showcasing the freshest of produce and the perfect wine to match while enjoying the overlooking the spectacular Bannockburn Inlet.
          Depending on final numbers, additional or alternative wineries may be sourced for this lunch such as Mt Difficulty, equally as stunning.
          We have the option to visit Arrowtown on the way to Queenstown, a charming and quirky a delightful gold rush village nestled below the beautiful peaks that surround the sparkling Arrow River.
          You’ll be enchanted by historic Arrowtown, a New Zealand treasure! Settle into our accommodation in Queenstown for another included restaurant dinner and prepare ourselves for an incredible day of scenery coming up!
        </p>
        <p><b><i>Included Meals:</i></b> Breakfast, Lunch, Dinner</p>
        <p><b><i>Included Accommodation:</i></b> Novotel Queenstown or similar</p>
        <img src="https://static-hakatours.imgix.net/Queenstown_Skyline.jpg?h=418&w=652&fit=crop&auto=format" alt="Tekapo" />
      </div>
      <br />
      <div className="l-container l-container--small">
        <h2>Day 5: 28th March</h2>
        <h2>Queenstown</h2>
        <p>
          Today is a free day in Queenstown for you to explore the town and take in the sights! Start the day whenever you like depending on the activities you chose to include.
          Enjoy a sleep in or get up early to fit the most into your day, the choice is up to you! Queenstown has plenty to offer with plenty of short walks,
          bike tracks and cool eateries to try. You’ll never be bored. You may choose to take the Skyline Queenstown Gondola and marvel at the stunning views,
          take a ride on the TSS Earnslaw to Walter Peak with a gourmet BBQ dinner and farm demonstration included.
          Or take an adventure of a lifetime to the spectacular full day Milford Sound coach-cruise-coach tour. Lunch is provided on the Milford Sound Cruise options and a BBQ dinner is provided on the TSS Earnslaw Cruise.
        </p>
        <p><b><i>Included Meals:</i></b> Breakfast</p>
        <p>
          <b><i>Optional Activities:</i></b> TSS Earnslaw Cruise and Walter Peak Gourmet BBQ Dinner with Farm Demonstration, Milford Sound Half Day Fly/Cruise/fFy, Milford Sound Full Day Coach/Cruise/Coach
        </p>
        <p><b><i>Included Accommodation:</i></b> Novotel Queenstown or similar</p>
        <p>Please note, this is your guide’s day off so this day will be unguided. All transfers and bookings will be organised for you</p>
        <img src="https://static-hakatours.imgix.net/Milford_Sound_2.jpg?h=418&w=652&fit=crop&auto=format" alt="Tekapo" />
      </div>
      <br />
      <div className="l-container l-container--small">
        <h2>Day 6: 29th March</h2>
        <h2>Queenstown</h2>
        <p>
          Today is another free day to enjoy Queenstown with the option to participate in a golf tournament at Millbrook.
          Set in a natural alpine amphitheatre against the backdrop of the Remarkables Mountain Range this 27 hole golf course exploits the dramatic terrain and delivers world-class golf.
          Millbrook is simply an unforgettable Queenstown golfing experience. If you choose to take this option, you can pay your course fees directly to Millbrook.
          Those who may want to sit back and relax could choose to visit Onsen Hot Pools for a private hot tub experience with a view.
          Your knowledgeable guide will also be able to provide you with plenty of other options to fill your day with. Lunch is not included on this day.
          For those keen golfers your guide will transport you to the course. The rest of the group will join later in the evening for a group dinner at Millbrook. Please note that lunch is not included today.
        </p>
        <p><b><i>Included Meals:</i></b> Breakfast, Group Dinner at Millbrook</p>
        <p>
          <b><i>Optional Activities:</i></b> Onsen Hot Pools
        </p>
        <p><b><i>Included Accommodation:</i></b> Novotel Queenstown or similar</p>
        <img src="https://static-hakatours.imgix.net/Millbrook_Golf.jpg?h=418&w=652&fit=crop&auto=format" alt="Tekapo" />
      </div>
      <br />
      <div className="l-container l-container--small">
        <h2>Day 7: 30th March</h2>
        <h2>Queenstown to Mackenzie Country</h2>
        <p><b>Travel time:</b> 256 KM, approx 3.5 hours driving time.</p>
        <p><b>Start time:</b> Approximately 10-11am</p>
        <p>
          Today you will travel to Mackenzie Country. En route, you can visit Mt Cook National Park which is home to our tallest mountain in New Zealand.
          You will be greeted with views of 19 peaks over 3,000 metres high. You might like to take a scenic flight over either the Mt Cook or Tekapo area.
          This is truly the best way to take in the sights in this region. Lake Takepo, with the bright blue waters from the glaciers, is definitely one of the most picturesque locations you will visit.
          Be sure to take some photos by the famous Church of the Good Shepherd right on the lake. Lunch is included today.
          This evening you have an indoor/outdoor observatory tour included that will introduce you to the famous Tekapo night sky reserve at Mt John observatory.
          The clear night skies of Tekapo and the MacKenzie are excellent conditions for astronomy tours, during the hear from the local astronomer of the planets and stars in this stunning part of the universe.
        </p>
        <p><b><i>Included Meals:</i></b> Breakfast, Lunch, Dinner</p>
        <p><b><i>Included Activity:</i></b> Earth and Sky Mount John Observatory Tour</p>
        <p>
          <b><i>Suggested Activities:</i></b> Tekapo Hot Springs, Scenic Flight
        </p>
        <p><b><i>Included Accommodation:</i></b> Peppers Bluewater Resort or similar</p>
        <img src="https://static-hakatours.imgix.net/Mount_Cook_National_Park.jpg?h=418&w=652&fit=crop&auto=format" alt="Tekapo" />
      </div>
      <br />
      <div className="l-container l-container--small">
        <h2>Day 8: 31st March</h2>
        <h2>Lake Tekapo to Christchurch</h2>
        <p><b>Travel time:</b> 227 KM, approx 3.25 hours driving time.</p>
        <p><b>Start time:</b> Approximately 10-11am</p>
        <p>
          Today your guide will transfer you from Lake Tekapo to Christchurch via a scenic drive through the Canterbury region,
          filled with grassy plains and snowcapped mountains. Christchurch is also known as the gateway to Antarctica.
          You have a trip to the International Antarctic Center included in this afternoon. Here you’ll experience real snow and ice and you’ll survive an indoor Antarctic storm.
          You’ll learn about life at Scott Base and see what it takes to work on the ice. You also have a group farewell Gala dinner included this evening at the Tannery.
          The Tannery’s opulent Victorian era atrium as a canvas for your gala dinner.
          With its iconic tiled floors, mahogany shop fronts, stained glass, the characteristic iron arches and translucent roof, this reborn industrial precinct is your palette to create a truly memorable evening.
        </p>
        <p><b><i>Included Meals:</i></b> Breakfast, Lunch, Gala Dinner</p>
        <p><b><i>Included Activity:</i></b> International Antarctic Centre</p>
        <p><b><i>Included Accommodation:</i></b> Crowne Plaza Hotel or similar</p>
        <img src="https://static-hakatours.imgix.net/Tekapo.jpg?h=418&w=652&fit=crop&auto=format" alt="Tekapo" />
      </div>
      <br />
      <div className="l-container l-container--small">
        <h2>Day 9: 1st April</h2>
        <h2>Depart Christchurch</h2>
        <p>
          Today is your final day in New Zealand, we hope you have enjoyed your time with us on tour.
          Depending on your departure time there will be the option for additional activities.
          We have organised your guide to your transfer from your hotel to the airport in time for your departing international flights.
        </p>
      </div>
    </section>
  </Layout>
);

export default SchlumbergerPage;
